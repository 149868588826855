import React, {useEffect} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => {

    function jiraHelpdesk(callback) {
        const jhdScript = document.createElement('script');
        jhdScript.type = 'text/javascript';
        jhdScript.setAttribute('data-jsd-embedded', null);
        jhdScript.setAttribute('data-key', '37551174-1c62-4d6a-8e65-338b8d19d445');
        jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
        jhdScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
        if(jhdScript.readyState) { // old IE support
            jhdScript.onreadystatechange = function() {
                if ( jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete' ) {
                    jhdScript.onreadystatechange = null;
                    callback();
                }
            };
        } else { //modern browsers
            jhdScript.onload = function() {
                callback();
            };
        }
        document.getElementsByTagName('head')[0].appendChild(jhdScript);
    }

    useEffect(() => {
        jiraHelpdesk(function() {
            const DOMContentLoaded_event = document.createEvent('Event');
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        });
    }, []);

    return (
        <Layout pageInfo={{pageName: "contact"}}>
            <Seo title="TrackSecure Kontaktformular"
                 keywords={[`Feedback`, `Leistungen`, `Produkte`, `Rückruf`, `Fehler melden`, `TrackSecure`, `Kontaktforumular` ]}/>
            <h1 className="heading-4">Kontakt</h1>

            <p className="content-text"><br/>Du hast allgemeine Fragen zu unseren Produkten & Leistungen? <br/><br/> Bitte melde Dich
                bei uns per <a
                    href="mailto:com@tracksecure.atlassian.net">eMail</a>.
            </p>
        </Layout>
    );
}

export default ContactPage
